import $ from 'jquery'
import DOMelement from './DOMelement'
import setCookie from '@/utils/setCookie'

export default class StartupMessages extends DOMelement {
  constructor () {
    super('startup-messages.html')
    this.addToDOM($('body'))
    this.setTriggers()

    Promise.all(this.promises).then(() => {
      this.setPage()
    })
  }

  setPage = () => {
    this.cookieCheck()
    let done = true
    for (const [key, item] of Object.entries(this.paragraphs)) {
      if (
        (item.importance === 'mandatory' && item.cookie !== 'accepted') ||
        (item.importance === 'optional' && item.cookie === 'pending')
      ) {
        $('.disclaimer').fadeOut('fast', () => {
          $(this.document)
            .find('.scroll')
            .empty()
            .append(item.element)
          $(this.document).find('.disclaimer').attr({
            'data-current': key
          })

          $('.disclaimer').fadeOut('fast', () => {
            $('.disclaimer').fadeIn('fast')
          })

          $(this.document).find('button.refuse').css({
            visibility: ''
          })
          if (item.importance === 'mandatory') {
            $(this.document).find('button.refuse').css({
              visibility: 'hidden'
            })
          }
        })
        done = false
        break
      }
    }

    if (done) {
      $(this.document).fadeOut('fast', () => {
        $(this.document).remove()
      })
    }
  }

  setTriggers = () => {
    $(this.document)
      .find('button.approve')
      .on('click', (event) => {
        const action = $(event.currentTarget).attr('data-action')
        $(this.document).find(`div.${action}`).addClass('hidden')
      })

    $(this.document)
      .find('button.approve')
      .off()
      .on('click', () => {
        const current = $(this.document)
          .find('.disclaimer')
          .attr('data-current')
        setCookie(current, 'accepted')
        this.setPage()
      })

    $(this.document)
      .find('button.refuse')
      .off()
      .on('click', () => {
        const current = $(this.document)
          .find('.disclaimer')
          .attr('data-current')
        setCookie(current, 'refused')
        this.setPage()
      })

    $(this.document)
      .find('button.play-store')
      .off()
      .on('click', () => {
        window.open('https://play.google.com/store/apps/details?id=fr.snowmap', '_blank').focus()
      })
  }

  cookieCheck = () => {
    const ret = {}
    const cookies = document.cookie.split('; ')
    for (const i in cookies) {
      cookies[cookies[i].split('=')[0]] = cookies[i].split('=')[1]
      delete cookies[i]
    }

    // Reminder
    if (cookies.reminder === 'accepted') {
      ret.reminder = 'accepted'
    } else if (cookies.reminder === 'refused') {
      ret.reminder = 'refused'
    } else {
      setCookie('reminder', 'pending')
      ret.reminder = 'pending'
    }

    // Disclaimer
    if (cookies.disclaimer === 'accepted') {
      ret.disclaimer = 'accepted'
    } else {
      setCookie('disclaimer', 'pending')
      ret.disclaimer = 'pending'
    }

    // Privacy
    if (cookies.privacy === 'accepted' || cookies.privacy === 'refused') {
      ret.privacy = cookies.privacy
    } else {
      setCookie('privacy', 'pending')
      ret.privacy = 'pending'
    }

    // Play Store
    if (!navigator.userAgent.includes('Android')) {
      setCookie('playstore', 'accepted')
      ret.playstore = 'accepted'
    } else if (cookies.playstore === 'accepted') {
      ret.playstore = cookies.playstore
    } else {
      setCookie('playstore', 'pending', 7 * 24 * 60 * 60 * 1000)
      ret.playstore = 'pending'
    }

    this.paragraphs = {}
    $(this.document)
      .find('div.texts div')
      .each((_, item) => {
        this.paragraphs[$(item).attr('data-type')] = {
          importance: $(item).attr('data-importance'),
          element: item,
          cookie: ret[$(item).attr('data-type')]
        }
      })
  }
}
